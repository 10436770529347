export { default as CloseIcon } from './close.png';
export { default as LikeImg } from './like_ic_de.png';
export { default as LikedImg } from './like_ic_click.png';
export { default as UserListEmptyImg } from './userlist-empty.png';
export { default as SearchEmptyImg } from './search_empty_img.png';
export { default as receivedSupervise } from './received-supervise.png';
export { default as sendSupervise } from './send-supervise.png';
export { default as finishSupervise } from './finishSupervise.png';
export { default as TemplateIconDown } from './template-icon-down.png';
export { default as TemplateIcon } from './template-icon.png';
export { default as SuperviseDel } from './supervise-del.png';
export { default as finishDong } from './finishDong.png';
export { default as finishReminder } from './finishReminder.png';
export { default as NoDataIcon } from './no-content.png';
export { default as thirdApp } from './thirdApp/thirdApp_bg.png';
export { default as superviseEmpty } from './supervise-empty.png';
export { default as commentImg } from './comment.png';
// export { default as LikeImg } from './like.png';
// export { default as LikedImg } from './likered.png';
