export { default as crossPng } from './cross.png';
export { default as settingPng } from './setting.png';
export { default as searchDeletePng } from './search_delete.png';
export { default as addPng } from './add.png';
export { default as returnPng } from './return.png';
export { default as closePng } from './close.png';
export { default as leftPng } from './left.png';
export { default as rightPng } from './right.png';
export { default as topPng } from './top.png';
export { default as dropDownPng } from './dropdown.png';
export { default as remindPng } from './remind.png';
export { default as meetingPng } from './meeting.png';
export { default as locationPng } from './location.png';
export { default as calendarPng } from './calendar.png';
export { default as attachmentPng } from './attachment.png';
export { default as descriptionPng } from './description.png';
export { default as deletePng } from './delete.png';
export { default as draftPng } from './draft.png';
export { default as timesPng } from './times.png';
export { default as repeatPng } from './repeat.png';
export { default as userPng } from './user.png';
export { default as busyPng } from './busy.png';
export { default as communicationPng } from './communication.png';
export { default as putawayPng } from './putaway.png';
export { default as summaryPng } from './summary.png';
export { default as cloudfilePng } from './cloudfile.png';
export { default as localfilePng } from './localfile.png';
export { default as uploadingPng } from './uploading.png';
export { default as sharelogoPng } from './sharelogo.png';
