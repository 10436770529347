// export { default as exPng } from './bg_excel@3x.png';
// export { default as cloudPng } from './bg_cloud@3x.png';
// export { default as compressionPng } from './bg_compression@3x.png';
// export { default as filePng } from './bg_file@3x.png';
// export { default as webPng } from './bg_web@3x.png';
// export { default as musicPng } from './bg_music@3x.png';
// export { default as otherPng } from './bg_other@3x.png';
// export { default as pdfPng } from './bg_pdf@3x.png';
// export { default as picturePng } from './bg_picture@3x.png';
// export { default as pptPng } from './bg_ppt@3x.png';
// export { default as videoPng } from './bg_video@3x.png';
// export { default as wordPng } from './bg_word@3x.png';

// export { default as documentPng } from './bg_document@3x.png';
// export { default as weeklyPng } from './bg_weekly@3x.png';
// export { default as tablePng } from './bg_table@3x.png';

// export { default as ic_exPng } from './ic_excel@3x.png';
// export { default as ic_cloudPng } from './ic_cloud@3x.png';
// export { default as ic_compressionPng } from './ic_compression@3x.png';
// export { default as ic_webPng } from './ic_web@3x.png';
// export { default as ic_filePng } from './ic_file@3x.png';
// export { default as ic_musicPng } from './ic_music@3x.png';
// export { default as ic_otherPng } from './ic_other@3x.png';
// export { default as ic_pdfPng } from './ic_pdf@3x.png';
// export { default as ic_picturePng } from './ic_picture@3x.png';
// export { default as ic_pptPng } from './ic_ppt@3x.png';
// export { default as ic_videoPng } from './ic_video@3x.png';
// export { default as ic_wordPng } from './ic_word@3x.png';

// export { default as ic_weeklyPng } from './ic_weekly@3x.png';
// export { default as ic_documentPng } from './ic_document@3x.png';
// export { default as ic_formPng } from './ic_form@3x.png';

export { default as exPng } from './ic1_excel@2x.png';
export { default as cloudPng } from './ic1_cloud@2x.png';
export { default as compressionPng } from './ic1_compression@2x.png';
export { default as webPng } from './ic1_web@2x.png';
export { default as filePng } from './ic1_file@2x.png';
export { default as musicPng } from './ic1_music@2x.png';
export { default as otherPng } from './ic1_other@2x.png';
export { default as pdfPng } from './ic1_pdf@2x.png';
export { default as picturePng } from './ic1_picture@2x.png';
export { default as pptPng } from './ic1_ppt@2x.png';
export { default as videoPng } from './ic1_video@2x.png';
export { default as wordPng } from './ic1_word@2x.png';
export { default as documentPng } from './ic1_document@2x.png';
export { default as weeklyPng } from './ic1_weekly@2x.png';
export { default as tablePng } from './ic1_table@2x.png';
